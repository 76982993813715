<template>
  <b-modal
    id="generate-extract-modal"
    hide-header
    hide-footer
    centered
    size="xl"
    @show="getOnlendingsByIds"
  >
    <div class="header">
      <div class="header-content">
        <p>Gerar extrato</p>
        <Close class="close-svg" @click="onClose" />
      </div>
    </div>

    <b-container class="py-3 px-4">
      <b-row>
        <b-col cols="2">
          <p class="label">Favorecido</p>
          <p class="info">{{beneficiary?.name ?? ''}}</p>
        </b-col>
      </b-row>
      
      <hr class="my-4"/>

      <Filters @changeFilters="filterOnlendings"/>

      <b-row>
        <b-col 
          v-if="onlendingsToExtract?.length"
          cols="12">
          <b-card>
            <b-card-title class="card-title"> Itens conferidos </b-card-title>

            <b-table-simple striped responsive>
              <tbody>
                <tr class="tr-header">
                  <th>Data</th>
                  <th>Item</th>
                  <th>Perfil/Grau part</th>
                  <th>Paciente</th>
                  <th>Convênio</th>
                  <th>Valor Item</th>
                  <th>Valor Repasse</th>
                  <th>Regra Aplicada</th>
                </tr>

                <tr
                  v-for="onlending in onlendingsToExtract"
                  :key="onlending.id"
                  class="tr-header"
                >
                  <td> 
                    {{onlending.item_type === 'App\\Models\\TissGuide\\Item\\TissGuideItem' ?
                    (onlending.item && onlending.item.execution_date && moment(onlending.item.execution_date).format('DD/MM/YYYY') || '') :
                    (onlending.item && onlending.item.appointment && moment(onlending.item.appointment.start_datetime).format('DD/MM/YYYY') || '')}}  
                  </td>
                  <td>{{ onlending.item && onlending.item.item ? onlending.item.item.name : '-' }}</td>
                  <td>{{ onlending.rule_definition && onlending.rule_definition.profile ? onlending.rule_definition.profile.name : '-' }}</td>
                  <td>{{ 
                    onlending.origin_type === 'App\\Models\\TissGuide\\TissGuide' ?
                      (onlending.origin && onlending.origin.appointment && onlending.origin.appointment.patient ? onlending.origin.appointment.patient.name : 
                        (onlending.origin && onlending.origin.patient ? onlending.origin.patient.name : '-')
                      ): 
                      (onlending.item && onlending.item.appointment && onlending.item.appointment.patient ?
                       onlending.item.appointment.patient.name : '-')
                  }}</td>
                  <td>{{ onlending.health_plan ? onlending.health_plan.fantasy_name : '-' }}</td>
                  <td>{{ parseNumberToMoney(onlending.total_value) }}</td>
                  <td>
                      {{ parseNumberToMoney(onlending.onlending_value) }} <br>
                      ({{((onlending.onlending_value * 100) / onlending.total_value).toFixed(2)}} %) 
                  </td>
                  <td>{{ onlending.rule_definition && onlending.rule_definition.rule ? 'Regra #'+ onlending.rule_definition.rule.number + ' - ' +  onlending.rule_definition.rule.name : '-' }}</td>
                </tr>
              </tbody>
            </b-table-simple>

            <b-row align-h="end">
              <span class="result-label mr-3">
                Valor total 
                <b> {{parseNumberToMoney(onlendingsSum['total_value'] ?? 0)}} </b>
              </span>

              <span class="result-label">
                Valor de repasse 
                <b> {{parseNumberToMoney(onlendingsSum['onlending_value'] ?? 0)}} </b>
              </span>
            </b-row>


            <b-row class="justify-content-end mt-3">
              <b-pagination
                v-model="page"
                :total-rows="count"
                :per-page="limit"
                first-number
                last-number
                size="sm"
              />
            </b-row>
          </b-card>
        </b-col>

        <b-col 
          cols="12"
          v-else
        >
          <NoFilterResults
            message="Os filtros aplicados não geraram nenhum resultado."
          />

        </b-col>
      </b-row>
    </b-container>

    <b-row class="d-flex justify-content-end mr-4 mb-2">
        <b-button
          class="mr-2"
          variant="outline-primary"
          @click="onClose"
        >
          Voltar
        </b-button>
        <b-button
          variant="primary"
          @click="storeExtract"
        >
          Confirmar e gerar extrato
        </b-button>
    </b-row>
  </b-modal>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'
import api from '@/modules/onlending/api.js'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'GenerateExtractModal',
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    Filters: () => import('@/modules/onlending/components/extract/ModalFilters.vue'),
    NoFilterResults: () => import('@/components/General/noFilterResults'),
  },
  props: {
    beneficiary: Object,
    selectedOnlendings: Array
  },
  data() {
    return {
      clinic: getCurrentClinic(),

      onlendingsToExtract: [],
      onlendingsSum: [],

      headers: [
        '',
        'Data criação',
        'Paciente',
        'N° da guia',
        'Profissional exec.',
        'Prazo envio',
        'Tipo de guia',
        'Valor',
        'Conferido',
        'Ver menos'
      ],
      subHeaders: [
        'Atualização',
        'Tabela',
        'Cód. Item',
        'Descrição',
        'Valor faturado',
        'Valor pago'
      ],

      filters: {},

      page: 1,
      count: 0,
      limit: 0,
    }
  },
  computed: {
    totalValue(){
      let total = 0
      this.onlendingsToExtract.forEach(onlending => {
        total += onlending.total_value;
      });
      return total
    },
    onlendingValue(){
      let total = 0
      this.onlendingsToExtract.forEach(onlending => {
        total += onlending.onlending_value;
      });
      return total
    }
  },
  methods: {
    parseNumberToMoney,

    filterOnlendings(filters){
      this.filters = filters
      this.page = 1
      this.getOnlendingsByIds()
    },

    async getOnlendingsSumByIds(){
      const data = {
        clinic_id: this.clinic.id,
        ids: this.selectedOnlendings,
        filters: JSON.stringify({
          date: this.filters.date ?? null,
          item_id: this.filters.item_id ?? null,
          profile_id: [this.filters.profile?.value ?? null].filter(n => n),
          patient_id: [this.filters.patient_id ?? null].filter(n => n),
          health_plan_id: [this.filters.health_plan_id ?? null].filter(n => n),
          rule_id: [this.filters.rule?.value ?? null].filter(n => n)
        })
      }
      api.getOnlendingsSumByIds(data)
        .then( res => {
          this.onlendingsSum = res.data
        })
        .catch(error => {
          this.$toast.error(error.message)
        })
    },

    async getOnlendingsByIds(){
      if(this.page === 1) this.getOnlendingsSumByIds() 
      const isLoading = this.$loading.show()        
      const data = {
        clinic_id: this.clinic.id,
        ids: this.selectedOnlendings,
        filters: JSON.stringify({
          date: this.filters.date ?? null,
          item_id: this.filters.item_id ?? null,
          profile_id: [this.filters.profile?.value ?? null].filter(n => n),
          patient_id: [this.filters.patient_id ?? null].filter(n => n),
          health_plan_id: [this.filters.health_plan_id ?? null].filter(n => n),
          rule_id: [this.filters.rule?.value ?? null].filter(n => n)
        })
      }
      api.getOnlendingsByIds(this.page, data)
        .then( res => {
          this.onlendingsToExtract = res.data.data
          this.count = res.data.total;
          this.limit = res.data.per_page;
        })
        .catch(error => {
          this.$toast.error(error.message)
        })
        .finally(() => {
          isLoading.hide()
        })
    },
    onClose() {
      this.$bvModal.hide('generate-extract-modal')
    },

    async storeExtract() {
      const isLoading = this.$loading.show()
      try {
        await api.createOnlendingExtract({
          clinic_id: this.clinic.id,
          beneficiary_id: this.beneficiary.id,
          selected_onlendings: this.selectedOnlendings
        })
        this.$toast.success('Extratos gerados com sucesso')
        this.onClose()
        this.$emit('change-tab', { tab: 'Extratos', index: 1 })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      } 
    }
  },
  watch: {
    page() { this.getOnlendingsByIds() },
  }
}
</script>

<style lang="scss">
#generate-extract-modal {
  .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);

    .modal-body {
      padding: 0;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: var(--type-active);

      .header {
        border-bottom: 1px solid var(--neutral-200);

        .header-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 24px;

          .close-svg {
            height: 24px;
            width: 24px;
            fill: var(--neutral-500);
          }
        }
      }

      .body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .body-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 50%;

          p {
            margin: 40px 0;
          }

          .batch-number {
            font-weight: bold;
            color: var(--blue-500);
          }

          .warning-svg {
            height: 96px;
            width: 96px;
            rect:first-child {
              fill: var(--blue-100);
            }

            rect:nth-child(2) {
              fill: var(--blue-500);
            }
          }

          .buttons {
            display: flex;
            flex-direction: row;

            .button {
              width: 168px;
              height: 40px;
              &:last-child {
                margin-left: 2%;
              }
            }

            .outline {
              margin: 0 5px;
              color: var(--neutral-000);
            }
          }
        }
      }
    }
  }

  .footer-text{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 28px;
    color: var(--type-active);
  }

  .label{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 130%;
    color: var(--dark-blue);
    margin-bottom: 6px;
  }

  .info{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    color: var(--type-active);
  }

  .card-title{
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 134%;
    letter-spacing: -0.01em;
    color: var(--dark-blue);
  }

  table th, .table td {
    padding: 0.75rem 0;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
    /* border: none; */
  }

  .tr-header {
    th {
      padding: 16px 8px;
    }

    .table th, .table td {
      padding: 0;
      vertical-align: middle;
      border-top: none;
    }

    td {
      padding: 16px 8px !important;
    }
  }

  th, td {
    text-align: left;
  }

  th {
    font-size: 14px;
    color: var(--dark-blue);
  }

  td{
    font-size: 15px;
    color: var(--type-active);
    padding: 0.75rem !important;
  }

  
  .result-label{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--type-active);
    padding: 8px 16px;
    height: 40px;
    background: var(--blue-100);
    border-radius: 8px;

    b{
      font-weight: 700;
      color: var(--blue-500);
    }
  }
}
</style>
